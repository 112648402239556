@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --black: #0b0b0b;
  --black-2: #121212;
  --gray: #adadad;
  --dark-gray: #222222;
  --red: #fe311e;
  --blue: #0061fe;
  --yellow: #ffd900;
  --purple: #7800ff;
  --yellow-opacity: #ffd90050;
  --green: #01c15a;
  line-height: normal;
  scroll-behavior: smooth;
  font-family: "Lama Sans", sans-serif;
}

@font-face {
  font-family: "Lama Sans";
  src: url("../public/fonts/LamaSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lama Sans";
  src: url("../public/fonts/LamaSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lama Sans";
  src: url("../public/fonts/LamaSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.bg-pattern {
  position: relative;
}

.bg-pattern::before {
  content: "";
  margin: 0;
  overflow-x: hidden;
  overflow-y: overlay;
  background-image: url(../public/pattern.svg);
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  filter: grayscale(1);
  opacity: 0.05;
  z-index: 10;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.bg-image {
  background-image: url("../public/icon.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.transition-max-height {
  transition: max-height 0.12s ease-in-out;
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showScale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.hide {
  animation: hide 0.5s;
}

.show {
  animation: show 0.5s;
}

.show-modal {
  animation: showScale 0.2s forwards;
}

::-webkit-scrollbar {
  width: 10px;
  margin: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--red);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--black);
}

.odometer {
  font-family: "Lama Sans", sans-serif !important;
}

@keyframes increment {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
  
}

.increment {
  transition: transform 0.5s;
  animation: increment 0.5s;
}